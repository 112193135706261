.form-container {
    .h1 {
      color: #14586b;
      font-size: 3rem;
      border: 2px solid #14586b;
      padding: 20px;
    //   margin: 5% 5%;
    display:flex;
    justify-content: center
    }
    max-width: 900px;
    width:100%;
    // height: 50%;
    border: 2px solid #14586b;
    // padding: 20px;
    // margin: 20%;
    // width: 460px;
    // margin: 5% 25% 10% 25%;
    padding: 32px;
    font-weight: bold;
    // background-color: #e0e0e0;
    //   box-shadow: 2px 2px 10px 10px rgba(0, 0, 0, 0.1);
    margin: 8% auto;
  
    .field {
      margin: 10% 0;
    }
  
    input {
      // display: none;
      width: 90%;
      display: flex;
      justify-content: center;
      padding: 10px 20px;
      background: #f4f7f8;
      margin: 5px auto;
      padding: 20px;
      background: #f4f7f8;
      border-radius: 8px;
    }
    .button-container {
        display: flex;
        justify-content: center;
    }
  
    button {
    //   padding: 12px 20px;
    display: flex;
    justify-content: center;
      border-style: none;
      background-color: #14586b;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
      font-size: 2rem;
      font-weight: 500;
      color: #fff;
      cursor: pointer;
      outline: none;
      margin: 15%;
      width: 50%;
      height: 75px;
      // border-radius: 5% 5% 5% 5%;
    }
}